<template>
  <wx-open-launch-app
    id="launch-btn"
    @error="handleError"
    @launch="handleLaunch"
    @ready="handleReady"
    appid="wx3ecb7168d10629e9"
    :extinfo="dataStr"
  >
    <!-- :extinfo="dataStr" -->
    <script type="text/wxtag-template">
      <style>
        .btn {
          padding: 50% 0;
        }
        .download {
          width:100%;
          height:100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          border-radius: 2px;
          color: #fff;
          background-color: transparent;
        }
        * { -webkit-tap-highlight-color: transparent !important; }
      </style>
      <div class="download btn" style="height:100%;width:100%;border:none;"></div>
    </script>
  </wx-open-launch-app>
</template>

<script>

export default {
  data() {
    return {
      courseId: this.$route.query.courseId,
      dataStr:''
    };
  },
  mounted() {
    let data = {
      type: 'coursedetail',
      data:{
        id: `${this.courseId}`
      }
    };
    this.dataStr=JSON.stringify(data);
  },
  methods: {
    handleError(e) {
      console.log("error", e, e.detail);
      // 跳到下载download.html页面
      // Dialog.alert({ message: "error:" + JSON.stringify(e) });
      if (e.isTrusted == false) {
        // alert("跳转失败")
        window.open("https://xingjiaren-api.hailiangedu.com/app/download");
      }
    },
    handleReady(e) {
      console.log("ready", e);
      // Dialog.alert({ message: "ready:" + JSON.stringify(e) });
    },
    handleLaunch(e) {
      console.log("success", e, e.detail);
      // Dialog.alert({ message: "success:" + JSON.stringify(e) });
    },
  },
};
</script>

<style scoped>
#launch-btn {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>